define('ui/k8s-tab/kubectl/route', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.get('store').findAll('container').then(function (containers) {
        var inst = null;
        for (var i = 0; i < containers.get('length'); i++) {
          var container = containers.objectAt(i);
          if (container.get('state') !== 'running') {
            continue;
          }

          var labels = container.get('labels') || {};
          if (labels[_uiUtilsConstants['default'].LABEL.K8S_KUBECTL] + '' === 'true') {
            inst = container;
            break;
          }
        }

        if (inst) {
          return _ember['default'].Object.create({
            command: ['/bin/bash', '-l', '-c', 'echo "# Run kubectl commands inside here\n# e.g. kubectl get rc\n"; TERM=xterm-256color /bin/bash'],
            instance: inst
          });
        } else {
          return _ember['default'].RSVP.reject('Unable to find an active kubectld container');
        }
      });
    },

    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        controller.set('step', 1);
        controller.set('kubeconfig', null);
      }
    }
  });
});