define('ui/k8s-tab/kubectl/controller', ['exports', 'ember'], function (exports, _ember) {

  var CONFIG_TPL = 'apiVersion: v1\nkind: Config\nclusters:\n- cluster:\n    api-version: v1%maybeInsecure%\n    server: "%baseUrl%/r/projects/%projectId%/kubernetes"\n  name: "%projectName%"\ncontexts:\n- context:\n    cluster: "%projectName%"\n    user: "%projectName%"\n  name: "%projectName%"\ncurrent-context: "%projectName%"\nusers:\n- name: "%projectName%"\n  user:\n    username: "%publicValue%"\n    password: "%secretValue%"';

  exports['default'] = _ember['default'].Controller.extend({
    access: _ember['default'].inject.service(),
    growl: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),

    step: 1,
    kubeconfig: '',

    downloadUrl: 'http://kubernetes.io/docs/user-guide/prereqs/',

    actions: {
      generate: function generate() {
        var _this = this;

        this.set('step', 2);

        var name = this.get('access.identity.name');
        if (name) {
          name = 'kubectl: ' + name;
        } else {
          name = 'kubectl';
        }

        this.get('store').createRecord({
          type: 'apiKey',
          name: name,
          description: 'Provides workstation access to kubectl'
        }).save().then(function (key) {
          var base = window.location.origin;
          var insecure = false;
          if (base.indexOf('http://') === 0) {
            base = base.replace(/^http:\/\//, 'https://');
            insecure = true;
          }

          var config = CONFIG_TPL.replace(/%baseUrl%/g, base).replace(/%maybeInsecure%/g, insecure ? '\n    insecure-skip-tls-verify: true' : '').replace(/%projectName%/g, _this.get('projects.current.displayName')).replace(/%projectId%/g, _this.get('projects.current.id')).replace(/%publicValue%/g, key.get('publicValue')).replace(/%secretValue%/g, key.get('secretValue'));

          _this.set('kubeconfig', config);
          _this.set('step', 3);
        })['catch'](function (err) {
          _this.set('step', 1);
          _this.get('growl').fromError('Error creating API Key', err);
        });
      }
    }
  });
});