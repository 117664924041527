define('ui/components/page-header-environment/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    currentPath: null,
    isOwner: null,

    tagName: '',

    projects: _ember['default'].inject.service(),
    project: _ember['default'].computed.alias('projects.current'),

    projectChoices: (function () {
      return this.get('projects.active').sortBy('name', 'id');
    }).property('projects.active.@each.{id,displayName,state}'),

    projectIsMissing: (function () {
      return this.get('projectChoices').filterBy('id', this.get('project.id')).get('length') === 0;
    }).property('project.id', 'projectChoices.@each.id'),

    actions: {
      switchProject: function switchProject(id) {
        this.sendAction('switchProject', id);
      },

      switchNamespace: function switchNamespace(id) {
        this.sendAction('switchNamespace', id);
      }
    }
  });
});