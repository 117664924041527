define("ui/components/view-edit-project/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 6
              },
              "end": {
                "line": 6,
                "column": 6
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["viewEditProject.showEdit.edit"], [], ["loc", [null, [5, 8], [5, 45]]], 0, 0], ["content", "originalProject.displayName", ["loc", [null, [5, 46], [5, 77]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 6
              },
              "end": {
                "line": 8,
                "column": 6
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["viewEditProject.showEdit.add"], [], ["loc", [null, [7, 8], [7, 44]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 10,
              "column": 2
            }
          },
          "moduleName": "ui/components/view-edit-project/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h1");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "editing", ["loc", [null, [4, 12], [4, 19]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [4, 6], [8, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 8
              },
              "end": {
                "line": 11,
                "column": 70
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "t", ["viewEditProject.header"], [], ["loc", [null, [11, 40], [11, 70]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 6
              },
              "end": {
                "line": 14,
                "column": 6
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "select-dot", [], ["model", ["subexpr", "@mut", [["get", "obj", ["loc", [null, [13, 29], [13, 32]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [13, 10], [13, 34]]], 0, 0]],
          locals: ["obj"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 2
            },
            "end": {
              "line": 20,
              "column": 2
            }
          },
          "moduleName": "ui/components/view-edit-project/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h1");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "pull-right");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element27 = dom.childAt(fragment, [5]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[2] = dom.createMorphAt(element27, 1, 1);
          morphs[3] = dom.createMorphAt(element27, 3, 3);
          return morphs;
        },
        statements: [["block", "link-to", ["settings.projects"], [], 0, null, ["loc", [null, [11, 8], [11, 82]]]], ["block", "power-select", [], ["options", ["subexpr", "@mut", [["get", "allProjects", ["loc", [null, [12, 30], [12, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "@mut", [["get", "project", ["loc", [null, [12, 51], [12, 58]]], 0, 0, 0, 0]], [], [], 0, 0], "onchange", ["subexpr", "action", ["changeProject"], [], ["loc", [null, [12, 68], [12, 92]]], 0, 0], "searchField", "displayName"], 1, null, ["loc", [null, [12, 6], [14, 23]]]], ["inline", "header-state", [], ["model", ["subexpr", "@mut", [["get", "project", ["loc", [null, [17, 27], [17, 34]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [17, 6], [17, 36]]], 0, 0], ["inline", "action-menu", [], ["model", ["subexpr", "@mut", [["get", "project", ["loc", [null, [18, 26], [18, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "size", "sm", "classNames", "r-ml10"], ["loc", [null, [18, 6], [18, 65]]], 0, 0]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 12
                },
                "end": {
                  "line": 43,
                  "column": 12
                }
              },
              "moduleName": "ui/components/view-edit-project/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "href", "#");
              dom.setAttribute(el1, "tabindex", "1");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "clip");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element23 = dom.childAt(fragment, [1]);
              var element24 = dom.childAt(element23, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element23, 'class');
              morphs[1] = dom.createElementMorph(element23);
              morphs[2] = dom.createAttrMorph(element24, 'src');
              morphs[3] = dom.createMorphAt(dom.childAt(element23, [3]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["nav-box-item driver orchestration-driver r-p5 ", ["subexpr", "if", [["subexpr", "eq", [["get", "project.projectTemplateId", ["loc", [null, [39, 136], [39, 161]]], 0, 0, 0, 0], ["get", "tpl.id", ["loc", [null, [39, 162], [39, 168]]], 0, 0, 0, 0]], [], ["loc", [null, [39, 132], [39, 169]]], 0, 0], "active"], [], ["loc", [null, [39, 127], [39, 180]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["selectTemplate", ["get", "tpl.id", ["loc", [null, [39, 65], [39, 71]]], 0, 0, 0, 0]], [], ["loc", [null, [39, 39], [39, 73]]], 0, 0], ["attribute", "src", ["concat", [["get", "tpl.image", ["loc", [null, [40, 28], [40, 37]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "tpl.name", ["loc", [null, [41, 34], [41, 46]]], 0, 0, 0, 0]],
            locals: ["tpl"],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 47,
                  "column": 8
                },
                "end": {
                  "line": 51,
                  "column": 8
                }
              },
              "moduleName": "ui/components/view-edit-project/template.hbs"
            },
            isEmpty: false,
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "text-center");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2, "class", "text-capitalize");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(": ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element22 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element22, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(element22, 3, 3);
              return morphs;
            },
            statements: [["content", "category", ["loc", [null, [49, 43], [49, 55]]], 0, 0, 0, 0], ["content", "names", ["loc", [null, [49, 65], [49, 74]]], 0, 0, 0, 0]],
            locals: ["category", "names"],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 51,
                  "column": 8
                },
                "end": {
                  "line": 55,
                  "column": 8
                }
              },
              "moduleName": "ui/components/view-edit-project/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "text-center");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2, "class", "text-capitalize");
              var el3 = dom.createTextNode("Orchestration");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(": Cattle\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 2
              },
              "end": {
                "line": 58,
                "column": 2
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("section");
            dom.setAttribute(el1, "class", "well");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "row");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "col-xs-12");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "nav nav-boxes checked-active");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element25 = dom.childAt(fragment, [1, 1]);
            var element26 = dom.childAt(element25, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element26, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element26, [3]), 1, 1);
            morphs[2] = dom.createMorphAt(element25, 3, 3);
            return morphs;
          },
          statements: [["inline", "t", ["viewEditProject.showEdit.template"], [], ["loc", [null, [36, 17], [36, 58]]], 0, 0], ["block", "each", [["get", "templateChoices", ["loc", [null, [38, 20], [38, 35]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [38, 12], [43, 21]]]], ["block", "each-in", [["get", "selectedProjectTemplate.summary", ["loc", [null, [47, 19], [47, 50]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [47, 8], [55, 20]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 0
            },
            "end": {
              "line": 59,
              "column": 0
            }
          },
          "moduleName": "ui/components/view-edit-project/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("section");
          dom.setAttribute(el1, "class", "well r-mb0");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "form-name-description", [], ["model", ["subexpr", "@mut", [["get", "project", ["loc", [null, [26, 12], [26, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "namePlaceholder", "viewEditDescription.form.name.placeholder", "descriptionPlaceholder", "viewEditDescription.form.description.placeholder"], ["loc", [null, [25, 4], [29, 6]]], 0, 0], ["block", "unless", [["get", "editing", ["loc", [null, [32, 12], [32, 19]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [32, 2], [58, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 59,
                "column": 0
              },
              "end": {
                "line": 64,
                "column": 0
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("section");
            dom.setAttribute(el1, "class", "well r-mb0");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2, "class", "inline-block r-m0");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(": ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            dom.setAttribute(el2, "class", "inline-block r-m0");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element21 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element21, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element21, [3]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["viewEditProject.description"], [], ["loc", [null, [61, 37], [61, 72]]], 0, 0], ["content", "project.description", ["loc", [null, [62, 33], [62, 56]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 59,
              "column": 0
            },
            "end": {
              "line": 64,
              "column": 0
            }
          },
          "moduleName": "ui/components/view-edit-project/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "project.description", ["loc", [null, [59, 10], [59, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [59, 0], [64, 0]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 70,
              "column": 4
            },
            "end": {
              "line": 74,
              "column": 4
            }
          },
          "moduleName": "ui/components/view-edit-project/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-group");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "input-identity", [], ["action", "checkMember", "onError", "error"], ["loc", [null, [72, 8], [72, 63]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 83,
                "column": 12
              },
              "end": {
                "line": 83,
                "column": 54
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("th");
            dom.setAttribute(el1, "width", "40");
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 96,
                    "column": 20
                  },
                  "end": {
                    "line": 98,
                    "column": 20
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("option");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element16 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element16, 'value');
                morphs[1] = dom.createAttrMorph(element16, 'selected');
                morphs[2] = dom.createMorphAt(element16, 0, 0);
                return morphs;
              },
              statements: [["attribute", "value", ["get", "choice.value", ["loc", [null, [97, 38], [97, 50]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "selected", ["subexpr", "eq", [["get", "member.role", ["loc", [null, [97, 67], [97, 78]]], 0, 0, 0, 0], ["get", "choice.value", ["loc", [null, [97, 79], [97, 91]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [97, 93]]], 0, 0], 0, 0, 0, 0], ["inline", "t", [["get", "choice.label", ["loc", [null, [97, 98], [97, 110]]], 0, 0, 0, 0]], [], ["loc", [null, [97, 94], [97, 112]]], 0, 0]],
              locals: ["choice"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 94,
                  "column": 16
                },
                "end": {
                  "line": 100,
                  "column": 16
                }
              },
              "moduleName": "ui/components/view-edit-project/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("select");
              dom.setAttribute(el1, "class", "form-control input-sm");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element17 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element17, 'onchange');
              morphs[1] = dom.createMorphAt(element17, 1, 1);
              return morphs;
            },
            statements: [["attribute", "onchange", ["subexpr", "action", [["subexpr", "mut", [["get", "member.role", ["loc", [null, [95, 79], [95, 90]]], 0, 0, 0, 0]], [], ["loc", [null, [95, 74], [95, 91]]], 0, 0]], ["value", "target.value"], ["loc", [null, [null, null], [95, 114]]], 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "roleOptions", ["loc", [null, [96, 28], [96, 39]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [96, 20], [98, 29]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 100,
                  "column": 16
                },
                "end": {
                  "line": 102,
                  "column": 16
                }
              },
              "moduleName": "ui/components/view-edit-project/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "uc-first", [["get", "member.role", ["loc", [null, [101, 29], [101, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [101, 18], [101, 42]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 106,
                    "column": 18
                  },
                  "end": {
                    "line": 108,
                    "column": 18
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "class", "btn btn-primary btn-sm pull-right gh-action");
                var el2 = dom.createElement("i");
                dom.setAttribute(el2, "class", "icon icon-minus");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element15 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element15);
                return morphs;
              },
              statements: [["element", "action", ["removeMember", ["get", "member", ["loc", [null, [107, 104], [107, 110]]], 0, 0, 0, 0]], [], ["loc", [null, [107, 80], [107, 112]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 108,
                    "column": 18
                  },
                  "end": {
                    "line": 110,
                    "column": 18
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                     \n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 104,
                  "column": 14
                },
                "end": {
                  "line": 112,
                  "column": 14
                }
              },
              "moduleName": "ui/components/view-edit-project/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("td");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "if", [["get", "accessEnabled", ["loc", [null, [106, 24], [106, 37]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [106, 18], [110, 25]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 87,
                "column": 10
              },
              "end": {
                "line": 114,
                "column": 10
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(element18, [1]), 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(element18, [3]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element18, [5]), 1, 1);
            morphs[3] = dom.createMorphAt(element18, 7, 7);
            return morphs;
          },
          statements: [["inline", "identity-block", [], ["identity", ["subexpr", "@mut", [["get", "member", ["loc", [null, [90, 42], [90, 48]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [90, 16], [90, 50]]], 0, 0], ["content", "member.displayType", ["loc", [null, [92, 18], [92, 40]]], 0, 0, 0, 0], ["block", "if", [["get", "showEdit", ["loc", [null, [94, 22], [94, 30]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [94, 16], [102, 23]]]], ["block", "if", [["get", "showEdit", ["loc", [null, [104, 20], [104, 28]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [104, 14], [112, 21]]]]],
          locals: ["member"],
          templates: [child0, child1, child2]
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 114,
                "column": 10
              },
              "end": {
                "line": 120,
                "column": 10
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "colspan", "3");
            dom.setAttribute(el2, "class", "text-center text-muted");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "t", ["viewEditProject.showEdit.noMembers"], [], ["loc", [null, [117, 16], [117, 58]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 76,
              "column": 4
            },
            "end": {
              "line": 124,
              "column": 4
            }
          },
          "moduleName": "ui/components/view-edit-project/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("table");
          dom.setAttribute(el1, "class", "grid fixed");
          dom.setAttribute(el1, "style", "margin-bottom: 0;");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("thead");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tbody");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("hr");
          dom.setAttribute(el1, "class", "r-m0");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [1]);
          var element20 = dom.childAt(element19, [1, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(element20, 1, 1);
          morphs[1] = dom.createMorphAt(element20, 3, 3);
          morphs[2] = dom.createMorphAt(element20, 5, 5);
          morphs[3] = dom.createMorphAt(element20, 7, 7);
          morphs[4] = dom.createMorphAt(dom.childAt(element19, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "sortable-th", [], ["sortable", ["subexpr", "@mut", [["get", "this", ["loc", [null, [80, 35], [80, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "changeSort", "name", "name", "label", "generic.name"], ["loc", [null, [80, 12], [80, 94]]], 0, 0], ["inline", "sortable-th", [], ["sortable", ["subexpr", "@mut", [["get", "this", ["loc", [null, [81, 35], [81, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "changeSort", "name", "type", "label", "generic.type"], ["loc", [null, [81, 12], [81, 94]]], 0, 0], ["inline", "sortable-th", [], ["sortable", ["subexpr", "@mut", [["get", "this", ["loc", [null, [82, 35], [82, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "changeSort", "name", "role", "label", "generic.role"], ["loc", [null, [82, 12], [82, 94]]], 0, 0], ["block", "if", [["get", "showEdit", ["loc", [null, [83, 18], [83, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [83, 12], [83, 61]]]], ["block", "each", [["get", "arranged", ["loc", [null, [87, 18], [87, 26]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [87, 10], [120, 19]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 124,
              "column": 4
            },
            "end": {
              "line": 128,
              "column": 4
            }
          },
          "moduleName": "ui/components/view-edit-project/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "help-block text-center");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "format-html-message", ["viewEditProject.helpBlock"], [], ["loc", [null, [126, 8], [126, 59]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child7 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 144,
                "column": 6
              },
              "end": {
                "line": 150,
                "column": 6
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-12");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("p");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["viewEditProject.networkPolicy.noManager"], [], ["loc", [null, [147, 15], [147, 62]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 150,
                  "column": 6
                },
                "end": {
                  "line": 156,
                  "column": 6
                }
              },
              "moduleName": "ui/components/view-edit-project/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-xs-12");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("p");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["viewEditProject.networkPolicy.unsupported"], [], ["loc", [null, [153, 15], [153, 64]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 160,
                    "column": 12
                  },
                  "end": {
                    "line": 167,
                    "column": 12
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "radio");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "radio");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [1, 1]);
                var element7 = dom.childAt(fragment, [3, 1]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(element6, 0, 0);
                morphs[1] = dom.createMorphAt(element6, 2, 2);
                morphs[2] = dom.createMorphAt(element7, 0, 0);
                morphs[3] = dom.createMorphAt(element7, 2, 2);
                return morphs;
              },
              statements: [["inline", "radio-button", [], ["selection", ["subexpr", "@mut", [["get", "npWithinLinked.action", ["loc", [null, [162, 48], [162, 69]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "allow"], ["loc", [null, [162, 23], [162, 85]]], 0, 0], ["inline", "t", ["viewEditProject.networkPolicy.allow"], [], ["loc", [null, [162, 91], [162, 134]]], 0, 0], ["inline", "radio-button", [], ["selection", ["subexpr", "@mut", [["get", "npWithinLinked.action", ["loc", [null, [165, 48], [165, 69]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "deny"], ["loc", [null, [165, 23], [165, 84]]], 0, 0], ["inline", "t", ["viewEditProject.networkPolicy.deny"], [], ["loc", [null, [165, 90], [165, 132]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 167,
                    "column": 12
                  },
                  "end": {
                    "line": 173,
                    "column": 12
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col-xs-12");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("p");
                dom.setAttribute(el3, "class", "text-muted");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]), 0, 0);
                return morphs;
              },
              statements: [["inline", "t", ["viewEditProject.networkPolicy.description"], [], ["loc", [null, [170, 40], [170, 89]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 177,
                    "column": 12
                  },
                  "end": {
                    "line": 184,
                    "column": 12
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "radio");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "radio");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [1, 1]);
                var element5 = dom.childAt(fragment, [3, 1]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(element4, 0, 0);
                morphs[1] = dom.createMorphAt(element4, 2, 2);
                morphs[2] = dom.createMorphAt(element5, 0, 0);
                morphs[3] = dom.createMorphAt(element5, 2, 2);
                return morphs;
              },
              statements: [["inline", "radio-button", [], ["selection", ["subexpr", "@mut", [["get", "npWithinService.action", ["loc", [null, [179, 48], [179, 70]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "allow"], ["loc", [null, [179, 23], [179, 86]]], 0, 0], ["inline", "t", ["viewEditProject.networkPolicy.allow"], [], ["loc", [null, [179, 92], [179, 135]]], 0, 0], ["inline", "radio-button", [], ["selection", ["subexpr", "@mut", [["get", "npWithinService.action", ["loc", [null, [182, 48], [182, 70]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "deny"], ["loc", [null, [182, 23], [182, 85]]], 0, 0], ["inline", "t", ["viewEditProject.networkPolicy.deny"], [], ["loc", [null, [182, 91], [182, 133]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 184,
                    "column": 12
                  },
                  "end": {
                    "line": 186,
                    "column": 12
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                return morphs;
              },
              statements: [["inline", "uc-first", [["get", "npWithinService.action", ["loc", [null, [185, 30], [185, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [185, 19], [185, 54]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child4 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 190,
                    "column": 12
                  },
                  "end": {
                    "line": 197,
                    "column": 12
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "radio");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "radio");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1, 1]);
                var element3 = dom.childAt(fragment, [3, 1]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(element2, 0, 0);
                morphs[1] = dom.createMorphAt(element2, 2, 2);
                morphs[2] = dom.createMorphAt(element3, 0, 0);
                morphs[3] = dom.createMorphAt(element3, 2, 2);
                return morphs;
              },
              statements: [["inline", "radio-button", [], ["selection", ["subexpr", "@mut", [["get", "npWithinStack.action", ["loc", [null, [192, 48], [192, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "allow"], ["loc", [null, [192, 23], [192, 84]]], 0, 0], ["inline", "t", ["viewEditProject.networkPolicy.allow"], [], ["loc", [null, [192, 90], [192, 133]]], 0, 0], ["inline", "radio-button", [], ["selection", ["subexpr", "@mut", [["get", "npWithinStack.action", ["loc", [null, [195, 48], [195, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "deny"], ["loc", [null, [195, 23], [195, 83]]], 0, 0], ["inline", "t", ["viewEditProject.networkPolicy.deny"], [], ["loc", [null, [195, 89], [195, 131]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child5 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 197,
                    "column": 12
                  },
                  "end": {
                    "line": 199,
                    "column": 12
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                return morphs;
              },
              statements: [["inline", "uc-first", [["get", "npWithinStack.action", ["loc", [null, [198, 30], [198, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [198, 19], [198, 52]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child6 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 203,
                    "column": 12
                  },
                  "end": {
                    "line": 210,
                    "column": 12
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "radio");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "radio");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1, 1]);
                var element1 = dom.childAt(fragment, [3, 1]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(element0, 0, 0);
                morphs[1] = dom.createMorphAt(element0, 2, 2);
                morphs[2] = dom.createMorphAt(element1, 0, 0);
                morphs[3] = dom.createMorphAt(element1, 2, 2);
                return morphs;
              },
              statements: [["inline", "radio-button", [], ["selection", ["subexpr", "@mut", [["get", "network.defaultPolicyAction", ["loc", [null, [205, 48], [205, 75]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "allow"], ["loc", [null, [205, 23], [205, 91]]], 0, 0], ["inline", "t", ["viewEditProject.networkPolicy.allow"], [], ["loc", [null, [205, 97], [205, 140]]], 0, 0], ["inline", "radio-button", [], ["selection", ["subexpr", "@mut", [["get", "network.defaultPolicyAction", ["loc", [null, [208, 48], [208, 75]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "deny"], ["loc", [null, [208, 23], [208, 90]]], 0, 0], ["inline", "t", ["viewEditProject.networkPolicy.deny"], [], ["loc", [null, [208, 96], [208, 138]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child7 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 210,
                    "column": 12
                  },
                  "end": {
                    "line": 212,
                    "column": 12
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                return morphs;
              },
              statements: [["inline", "uc-first", [["get", "network.defaultPolicyAction", ["loc", [null, [211, 30], [211, 57]]], 0, 0, 0, 0]], [], ["loc", [null, [211, 19], [211, 59]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 156,
                  "column": 6
                },
                "end": {
                  "line": 215,
                  "column": 6
                }
              },
              "moduleName": "ui/components/view-edit-project/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-md-3 col-sm-6");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-md-3 col-sm-6");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-md-3 col-sm-6");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-md-3 col-sm-6");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var element9 = dom.childAt(element8, [1]);
              var element10 = dom.childAt(element8, [3]);
              var element11 = dom.childAt(element8, [5]);
              var element12 = dom.childAt(element8, [7]);
              var morphs = new Array(8);
              morphs[0] = dom.createMorphAt(dom.childAt(element9, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(element9, 3, 3);
              morphs[2] = dom.createMorphAt(dom.childAt(element10, [1]), 0, 0);
              morphs[3] = dom.createMorphAt(element10, 3, 3);
              morphs[4] = dom.createMorphAt(dom.childAt(element11, [1]), 0, 0);
              morphs[5] = dom.createMorphAt(element11, 3, 3);
              morphs[6] = dom.createMorphAt(dom.childAt(element12, [1]), 0, 0);
              morphs[7] = dom.createMorphAt(element12, 3, 3);
              return morphs;
            },
            statements: [["inline", "t", ["viewEditProject.networkPolicy.withinLinked"], [], ["loc", [null, [159, 19], [159, 69]]], 0, 0], ["block", "if", [["get", "showEdit", ["loc", [null, [160, 18], [160, 26]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [160, 12], [173, 19]]]], ["inline", "t", ["viewEditProject.networkPolicy.withinService"], [], ["loc", [null, [176, 19], [176, 70]]], 0, 0], ["block", "if", [["get", "showEdit", ["loc", [null, [177, 18], [177, 26]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [177, 12], [186, 19]]]], ["inline", "t", ["viewEditProject.networkPolicy.withinStack"], [], ["loc", [null, [189, 19], [189, 68]]], 0, 0], ["block", "if", [["get", "showEdit", ["loc", [null, [190, 18], [190, 26]]], 0, 0, 0, 0]], [], 4, 5, ["loc", [null, [190, 12], [199, 19]]]], ["inline", "t", ["viewEditProject.networkPolicy.default"], [], ["loc", [null, [202, 19], [202, 64]]], 0, 0], ["block", "if", [["get", "showEdit", ["loc", [null, [203, 18], [203, 26]]], 0, 0, 0, 0]], [], 6, 7, ["loc", [null, [203, 12], [212, 19]]]]],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6, child7]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 150,
                "column": 6
              },
              "end": {
                "line": 215,
                "column": 6
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "hasUnsupportedPolicy", ["loc", [null, [150, 16], [150, 36]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [150, 6], [215, 6]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 133,
              "column": 0
            },
            "end": {
              "line": 218,
              "column": 0
            }
          },
          "moduleName": "ui/components/view-edit-project/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("section");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h4");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("hr");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "well");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "col-xs-12");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("p");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("hr");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element13 = dom.childAt(fragment, [1]);
          var element14 = dom.childAt(element13, [5]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element13, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element14, [1, 1, 1]), 0, 0);
          morphs[2] = dom.createMorphAt(element14, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["viewEditProject.networkPolicy.label"], [], ["loc", [null, [135, 8], [135, 51]]], 0, 0], ["inline", "t", ["viewEditProject.networkPolicy.description"], [], ["loc", [null, [140, 13], [140, 62]]], 0, 0], ["block", "if", [["get", "missingManager", ["loc", [null, [144, 12], [144, 26]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [144, 6], [215, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child8 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 220,
              "column": 0
            },
            "end": {
              "line": 223,
              "column": 0
            }
          },
          "moduleName": "ui/components/view-edit-project/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "top-errors", [], ["errors", ["subexpr", "@mut", [["get", "errors", ["loc", [null, [221, 22], [221, 28]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [221, 2], [221, 30]]], 0, 0], ["inline", "save-cancel", [], ["editing", ["subexpr", "@mut", [["get", "editing", ["loc", [null, [222, 24], [222, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "save", "save", "cancel", "cancel"], ["loc", [null, [222, 2], [222, 61]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 224,
            "column": 0
          }
        },
        "moduleName": "ui/components/view-edit-project/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "header clearfix");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("hr");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "well");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element28 = dom.childAt(fragment, [4]);
        var element29 = dom.childAt(element28, [5]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(dom.childAt(element28, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(element29, 1, 1);
        morphs[4] = dom.createMorphAt(element29, 3, 3);
        morphs[5] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[6] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "showEdit", ["loc", [null, [2, 8], [2, 16]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [20, 9]]]], ["block", "if", [["get", "showEdit", ["loc", [null, [23, 6], [23, 14]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [23, 0], [64, 7]]]], ["inline", "t", ["viewEditProject.showEdit.accessControl"], [], ["loc", [null, [67, 6], [67, 52]]], 0, 0], ["block", "if", [["subexpr", "and", [["get", "accessEnabled", ["loc", [null, [70, 15], [70, 28]]], 0, 0, 0, 0], ["get", "showEdit", ["loc", [null, [70, 29], [70, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [70, 10], [70, 38]]], 0, 0]], [], 4, null, ["loc", [null, [70, 4], [74, 11]]]], ["block", "if", [["get", "accessEnabled", ["loc", [null, [76, 10], [76, 23]]], 0, 0, 0, 0]], [], 5, 6, ["loc", [null, [76, 4], [128, 11]]]], ["block", "if", [["get", "network", ["loc", [null, [133, 6], [133, 13]]], 0, 0, 0, 0]], [], 7, null, ["loc", [null, [133, 0], [218, 7]]]], ["block", "if", [["get", "showEdit", ["loc", [null, [220, 6], [220, 14]]], 0, 0, 0, 0]], [], 8, null, ["loc", [null, [220, 0], [223, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
    };
  })());
});