define('ui/components/env-catalog/component', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _ember['default'].Component.extend({
    catalog: _ember['default'].inject.service(),
    project: null,
    catalogs: null,
    ary: null,
    global: null,

    kindChoices: [{ translationKey: 'catalogSettings.more.kind.native', value: 'native' }, { translationKey: 'catalogSettings.more.kind.helm', value: 'helm' }],

    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        ary: this.get('catalogs').filterBy('environmentId', this.get('project.id')),
        global: this.get('catalogs').filterBy('environmentId', 'global') // this should change to falsey check when josh updates the catalog to remove 'global' from the id
      });
    },
    actions: {
      add: function add() {
        var _this = this;

        this.get('ary').pushObject(_ember['default'].Object.create({
          name: '',
          branch: _uiUtilsConstants['default'].CATALOG.DEFAULT_BRANCH,
          url: '',
          kind: 'native',
          toAdd: true
        }));

        _ember['default'].run.next(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          _this.$('INPUT.name').last()[0].focus();
        });
      },
      remove: function remove(obj) {
        _ember['default'].set(obj, 'toRemove', true);
      },
      save: function save(cb) {
        var _this2 = this;

        if (this.validate()) {
          this.set('errors', []);
          var newCatalogs = this.get('ary').filterBy('toAdd', true);
          var catalogsToRemove = this.get('ary').filterBy('toRemove', true);
          var all = [];

          newCatalogs.forEach(function (cat) {
            all.push(_this2.addCatalogs(cat));
          });

          catalogsToRemove.forEach(function (cat) {
            all.push(_this2.removeCatalogs(cat));
          });

          _ember['default'].RSVP.all(all).then(function () {
            _this2.set('catalog.componentRequestingRefresh', true);
            _this2.set('saving', false);
            cb(true);
            _ember['default'].run.later(function () {
              _this2.sendAction('cancel');
            }, 500);
          })['catch'](function (err) {
            _this2.set('errors', err);
            cb(false);
            _this2.set('saving', false);
          });
        } else {
          cb(false);
          this.set('saving', false);
        }
      }
    },
    validate: function validate() {
      var errors = [];
      var globals = ['all', 'community', 'library']; // these should be removed when these terms are removed from the envid field
      var newCatalogs = this.get('ary').filterBy('toAdd', true);

      if (newCatalogs.length) {
        newCatalogs.forEach(function (cat) {
          if ((cat.name || '').trim().length === 0) {
            errors.push('A name is required');
          }
          if ((cat.url || '').trim().length === 0) {
            errors.push('A url is required');
          }
          if (globals.indexOf(cat.name.toLowerCase()) >= 0) {
            errors.push('Catalog name can not match a gloabl catalog name');
          }
        });
      }

      if (errors.length) {
        this.set('errors', errors.uniq());
        return false;
      } else {
        this.set('errors', null);
      }

      return true;
    },
    addCatalogs: function addCatalogs(catalogs) {
      return this.get('store').request({
        url: this.get('app.catalogEndpoint') + '/catalogs',
        method: 'POST',
        headers: _defineProperty({}, _uiUtilsConstants['default'].HEADER.PROJECT_ID, this.get('project.id')),
        body: JSON.stringify(catalogs)
      });
    },
    removeCatalogs: function removeCatalogs(catalogs) {
      return this.get('store').request({
        url: this.get('app.catalogEndpoint') + '/catalogs/' + catalogs.name,
        method: 'DELETE',
        headers: _defineProperty({}, _uiUtilsConstants['default'].HEADER.PROJECT_ID, this.get('project.id')),
        body: JSON.stringify(catalogs)
      });
    }
  });
});