define('ui/utils/parse-catalog-setting', ['exports', 'ui/utils/constants'], function (exports, _uiUtilsConstants) {
  exports.parseCatalogSetting = parseCatalogSetting;
  exports.getCatalogNames = getCatalogNames;

  // New JSON format: {"catalogs": {"foo": {"url":"...", "branch": "master"}, ...}}

  function parseCatalogSetting(str) {
    var out = {
      catalogs: {}
    };

    str = (str || '').trim();
    if (!str) {
      return out;
    }

    if (str.substr(0, 1) === '{') {
      // New JSON format
      try {
        var _ret = (function () {
          var json = JSON.parse(str);
          if (json && json.catalogs) {
            Object.keys(json.catalogs).forEach(function (k) {
              var entry = json.catalogs[k];
              if (!entry.branch) {
                entry.branch = _uiUtilsConstants['default'].CATALOG.DEFAULT_BRANCH;
              }
            });

            return {
              v: json
            };
          }
        })();

        if (typeof _ret === 'object') return _ret.v;
      } catch (e) {}

      // If parsing fails, return empty object
      return out;
    }

    // Old string format
    str.split(',').forEach(function (item) {
      var key = undefined,
          val = undefined;
      var idx = item.indexOf('=');
      if (idx > 0) {
        //Less old key=val,key2=val2 format
        key = item.substr(0, idx);
        val = item.substr(idx + 1);
      } else {
        // Even older single URL format
        key = _uiUtilsConstants['default'].EXTERNAL_ID.CATALOG_DEFAULT_GROUP;
        val = item;
      }

      key = key.trim();
      val = val.trim();
      if (key && val) {
        out.catalogs[key] = { url: val, branch: _uiUtilsConstants['default'].CATALOG.DEFAULT_BRANCH };
      }
    });

    return out;
  }

  function getCatalogNames(str) {
    return Object.keys(parseCatalogSetting(str).catalogs).sort();
  }
});