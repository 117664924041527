define('ui/components/page-footer/component', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'footer',
    className: 'clearfix',

    settings: _ember['default'].inject.service(),
    githubBase: _uiUtilsConstants['default'].EXT_REFERENCES.GITHUB,
    forumBase: _uiUtilsConstants['default'].EXT_REFERENCES.FORUM,
    slackBase: _uiUtilsConstants['default'].EXT_REFERENCES.SLACK,

    projectId: _ember['default'].computed.alias('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT),

    modalService: _ember['default'].inject.service('modal'),

    init: function init() {
      this._super.apply(this, arguments);
      var settings = this.get('settings');

      var cli = {};
      Object.keys(_uiUtilsConstants['default'].SETTING.CLI_URL).forEach(function (key) {
        cli[key.toLowerCase()] = settings.get(_uiUtilsConstants['default'].SETTING.CLI_URL[key]);
      });

      var compose = {};
      Object.keys(_uiUtilsConstants['default'].SETTING.COMPOSE_URL).forEach(function (key) {
        compose[key.toLowerCase()] = settings.get(_uiUtilsConstants['default'].SETTING.COMPOSE_URL[key]);
      });

      this.setProperties({
        cli: cli,
        compose: compose
      });
    },

    actions: {
      showAbout: function showAbout() {
        this.get('modalService').toggleModal('modal-about', {
          closeWithOutsideClick: true
        });
      }
    }
  });
});