define('ui/models/templateversion', ['exports', 'ember-api-store/models/resource', 'ui/utils/constants'], function (exports, _emberApiStoreModelsResource, _uiUtilsConstants) {
  exports['default'] = _emberApiStoreModelsResource['default'].extend({
    filesAsArray: (function () {
      var obj = this.get('files') || {};
      var out = [];

      Object.keys(obj).forEach(function (key) {
        out.push({ name: key, body: obj[key] });
      });

      return out;
    }).property('files'),

    supportsOrchestration: function supportsOrchestration(orch) {
      orch = orch.replace(/.*\*/, '');
      if (orch === 'k8s') {
        orch = 'kubernetes';
      }
      var list = ((this.get('labels') || {})[_uiUtilsConstants['default'].LABEL.ORCHESTRATION_SUPPORTED] || '').split(/\s*,\s*/).filter(function (x) {
        return x.length > 0;
      });
      return list.length === 0 || list.includes(orch);
    }
  });
});