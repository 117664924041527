define('ui/services/k8s', ['exports', 'ember', 'ember-api-store/models/error', 'ui/utils/constants'], function (exports, _ember, _emberApiStoreModelsError, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Service.extend({
    'tab-session': _ember['default'].inject.service(),
    store: _ember['default'].inject.service('store'),

    kubernetesEndpoint: (function () {
      return this.get('app.kubernetesEndpoint').replace(this.get('app.projectToken'), this.get('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT));
    }).property('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT, 'app.kubernetesEndpoint'),

    kubectlEndpoint: (function () {
      return this.get('app.kubectlEndpoint').replace(this.get('app.projectToken'), this.get('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT));
    }).property('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT, 'app.kubectlEndpoint'),

    kubernetesDashboard: (function () {
      return this.get('app.kubernetesDashboard').replace(this.get('app.projectToken'), this.get('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT));
    }).property('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT, 'app.kubernetesDashboard'),

    isReady: function isReady() {
      var _this = this;

      var store = this.get('store');
      return store.find('stack').then(function (stacks) {
        var stack = _this.filterSystemStack(stacks);
        if (stack) {
          return store.rawRequest({
            url: _this.get('kubernetesEndpoint') + '/version'
          }).then(function () {
            console.log('isReady: true');
            return true;
          })['catch'](function () {
            console.log('isReady: false');
            return false;
          });
        }

        console.log('isReady: false2');
        return false;
      })['catch'](function () {
        console.log('isReady: false3');
        return _ember['default'].RSVP.resolve(false);
      });
    },

    filterSystemStack: function filterSystemStack(stacks) {
      return (stacks || []).find(function (stack) {
        var info = stack.get('externalIdInfo');
        return (info.kind === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_CATALOG || info.kind === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_SYSTEM_CATALOG) && info.base === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_INFRA && info.name === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_KUBERNETES;
      });
    },

    parseKubectlError: function parseKubectlError(err) {
      return _emberApiStoreModelsError['default'].create({
        status: err.status,
        code: err.body.exitCode,
        message: err.body.stdErr.split(/\n/)
      });
    }
  });
});