define('ui/catalog-tab/index/controller', ['exports', 'ember', 'ui/utils/platform', 'ui/utils/constants'], function (exports, _ember, _uiUtilsPlatform, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller(),
    catalog: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),
    projectId: _ember['default'].computed.alias('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT),

    catalogController: _ember['default'].inject.controller('catalog-tab'),
    category: _ember['default'].computed.alias('catalogController.category'),
    categories: _ember['default'].computed.alias('model.categories'),
    catalogId: _ember['default'].computed.alias('catalogController.catalogId'),
    modalService: _ember['default'].inject.service('modal'),

    parentRoute: 'catalog-tab',
    launchRoute: 'catalog-tab.launch',

    search: '',

    updating: 'no',

    actions: {
      addEnvCatalog: function addEnvCatalog() {
        this.get('modalService').toggleModal('modal-edit-env-catalogs', {
          project: this.get('projects.current'),
          catalogs: this.get('model.catalogs.content')
        });
      },
      clearSearch: function clearSearch() {
        this.set('search', '');
      },
      launch: function launch(id, onlyAlternate) {
        if (onlyAlternate && !(0, _uiUtilsPlatform.isAlternate)(event)) {
          return false;
        }

        this.transitionToRoute(this.get('launchRoute'), id);
      },

      update: function update() {
        var _this = this;

        this.set('updating', 'yes');
        this.get('catalog').refresh().then(function () {
          _this.set('updating', 'no');
          _this.send('refresh');
        })['catch'](function () {
          _this.set('updating', 'error');
        });
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.get('catalog.componentRequestingRefresh');
    },

    childRequestiongRefresh: _ember['default'].observer('catalog.componentRequestingRefresh', function () {
      if (this.get('catalog.componentRequestingRefresh')) {
        this.send('update');
      }
    }),
    arrangedContent: _ember['default'].computed('model.catalog', 'search', function () {
      var search = this.get('search').toUpperCase();
      var result = [];

      if (!search) {
        return this.get('model.catalog');
      }

      this.get('model.catalog').forEach(function (item) {
        if (item.name.toUpperCase().indexOf(search) >= 0 || item.description.toUpperCase().indexOf(search) >= 0) {
          result.push(item);
        }
      });
      return result;
    })
  });
});