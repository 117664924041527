define('ui/components/settings/catalog-url/component', ['exports', 'ember', 'ui/utils/constants', 'ui/utils/parse-catalog-setting'], function (exports, _ember, _uiUtilsConstants, _uiUtilsParseCatalogSetting) {
  exports['default'] = _ember['default'].Component.extend({
    settings: _ember['default'].inject.service(),
    catalog: _ember['default'].inject.service(),

    initialValue: null,

    parsed: null,
    ary: null,
    enableLibrary: null,
    enableCommunity: null,

    actions: {
      add: function add() {
        var _this = this;

        this.get('ary').pushObject(_ember['default'].Object.create({ name: '', branch: _uiUtilsConstants['default'].CATALOG.DEFAULT_BRANCH, url: '' }));
        _ember['default'].run.next(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          _this.$('INPUT.name').last()[0].focus();
        });
      },

      remove: function remove(obj) {
        this.get('ary').removeObject(obj);
      },

      save: function save(btnCb) {
        var _this2 = this;

        var def = _uiUtilsConstants['default'].CATALOG.DEFAULT_BRANCH;

        var map = {};
        // Start with ours, then load the users in case they override the value
        if (this.get('enableLibrary')) {
          map[_uiUtilsConstants['default'].CATALOG.LIBRARY_KEY] = { url: _uiUtilsConstants['default'].CATALOG.LIBRARY_VALUE, branch: def };
        }

        if (this.get('enableCommunity')) {
          map[_uiUtilsConstants['default'].CATALOG.COMMUNITY_KEY] = { url: _uiUtilsConstants['default'].CATALOG.COMMUNITY_VALUE, branch: def };
        }

        // Load the user's non-empty rows
        this.get('ary').forEach(function (row) {
          var name = (row.name || '').trim();
          var url = (row.url || '').trim();
          var branch = (row.branch || '').trim() || def;

          if (name && url) {
            map[name] = { url: url, branch: branch };
          }
        });

        var neu = this.get('parsed') || {};
        neu.catalogs = map;

        this.get('settings').set(_uiUtilsConstants['default'].SETTING.CATALOG_URL, JSON.stringify(neu));
        this.get('settings').one('settingsPromisesResolved', function () {
          btnCb(true);
          _this2.sendAction('saved');
          _this2.get('catalog').reset();
        });
      }
    },

    init: function init() {
      this._super.apply(this, arguments);

      var def = _uiUtilsConstants['default'].CATALOG.DEFAULT_BRANCH;
      var parsed = (0, _uiUtilsParseCatalogSetting.parseCatalogSetting)(this.get('initialValue'));
      var map = parsed.catalogs || {};

      var library = false;
      var entry = map[_uiUtilsConstants['default'].CATALOG.LIBRARY_KEY];
      if (entry && entry.url === _uiUtilsConstants['default'].CATALOG.LIBRARY_VALUE && entry.branch === def) {
        library = true;
        delete map[_uiUtilsConstants['default'].CATALOG.LIBRARY_KEY];
      }

      var community = false;
      entry = map[_uiUtilsConstants['default'].CATALOG.COMMUNITY_KEY];
      if (entry && entry.url === _uiUtilsConstants['default'].CATALOG.COMMUNITY_VALUE && entry.branch === def) {
        community = true;
        delete map[_uiUtilsConstants['default'].CATALOG.COMMUNITY_KEY];
      }

      var ary = [];
      Object.keys(map).forEach(function (name) {
        ary.push(_ember['default'].Object.create({ name: name, branch: map[name].branch, url: map[name].url }));
      });

      this.setProperties({
        ary: ary,
        parsed: parsed,
        enableLibrary: library,
        enableCommunity: community
      });
    }
  });
});